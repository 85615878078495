import { isSafari } from '@site/js/utils/utils'

import { isMobile } from '../../../../site/js/utils/breakpoint'
import { initRequest } from '../../../../site/js/utils/request'
import { observeDecorativeVideos } from '../videoplayer/utils/helpers'

export class VideoStreaming {
  protected wrapperElement: HTMLDivElement

  constructor(element: HTMLDivElement) {
    this.wrapperElement = element
    this.init()
  }

  private init(): void {
    initRequest()
    if (!this.wrapperElement.classList.contains('cmp-videoembed')) {
      this.initVideo()
    }
  }

  private initVideo(): void {
    const video = this.wrapperElement.querySelector('video')
    const {
      videourl: src,
      posterimageurl: poster,
      mobileVideoDisabled,
      autoplay,
      disableplaypausedecorativeonmobile: disablePlayPauseDecorativeOnMobile,
      playpauseonhover: playPauseOnHover,
    } = this.wrapperElement.dataset

    if (!video.src) {
      video.src = src
    }

    if (!video.poster) {
      video.poster = poster
    }

    if (isSafari) {
      video.style.backgroundImage = `url('${poster}')`
    }

    video.autoplay = mobileVideoDisabled === 'true' && isMobile ? false : autoplay === 'true'
    video.preload = 'none'
    observeDecorativeVideos({ videoWrapperElement: this.wrapperElement, videoPlayer: video, disablePlayPauseDecorativeOnMobile, playPauseOnHover })
  }
}
