/* eslint-disable max-lines */
import { isMobile } from '@site/js/utils/breakpoint'

import { AttributesType, GetControlPropsType } from '../videoplayer-types'
import {
  BUTTON_HIDDEN_CLASS,
  BUTTON_ICON_CLASS,
  HIDDEN_ICON_CLASS,
  ICON_NO_SOUND_CLASS,
  ICON_SOUND_CLASS,
  PLAY_VIDEO_BUTTON_CLASS,
  VIDEO_CURRENT_TIME_CLASS,
  VIDEO_DURATION_TIME_CLASS,
  VIDEO_PROGRESS_BAR_BUFFER_CLASS,
  VIDEO_PROGRESS_BAR_CURRENT_TIME_CLASS,
  VIDEO_PROGRESS_BAR_HOVER_CLASS,
  VIDEO_PROGRESS_BAR_PROGRESS_CLASS,
} from './videoplayer-constants'

export const getControlItem = ({ parent, children, eventListenerElement, eventListeners, callback }: GetControlPropsType) => {
  const controlElement = document.createElement(parent.element)
  controlElement.classList.add(...parent.classes)

  if (parent.attributes) {
    setElementAttributes(controlElement as HTMLDivElement, parent.attributes)
  }

  if (parent.text) {
    controlElement.innerText = parent.text
  }

  children?.map(childObject => {
    const child = document.createElement(childObject.element)
    child.classList.add(...childObject.classes)

    if (childObject.attributes) {
      setElementAttributes(child as HTMLDivElement, childObject.attributes)
    }

    if (childObject.text) {
      child.innerText = childObject.text
    }

    controlElement.appendChild(child)
  })

  eventListeners?.forEach(event =>
    eventListenerElement ? eventListenerElement.addEventListener(event, callback) : controlElement.addEventListener(event, callback),
  )

  return controlElement
}

export const seekToTime = ({
  event,
  videoPlayer,
  wrapperElement,
  progressBarSelectorClass,
}: {
  event: MouseEvent
  videoPlayer: HTMLVideoElement
  wrapperElement: HTMLDivElement
  progressBarSelectorClass: string
}) => {
  const progressBarElement = wrapperElement.querySelector(`.${progressBarSelectorClass}`) as HTMLDivElement
  const seekToTime = (event.offsetX / progressBarElement.offsetWidth) * videoPlayer.duration
  if (event.type !== 'mousemove') {
    videoPlayer.currentTime = seekToTime
  } else {
    const hoverTimeDiv = wrapperElement.querySelector(`.${VIDEO_PROGRESS_BAR_HOVER_CLASS}`) as HTMLDivElement
    hoverTimeDiv.innerText = `${formatVideoTime(seekToTime)}`
    hoverTimeDiv.style.left = `${(seekToTime / videoPlayer.duration) * 100}%`
  }
}

export const seekCurrentTime = (videoPlayer: HTMLVideoElement, seconds: number, forward = true) =>
  forward ? (videoPlayer.currentTime += seconds) : (videoPlayer.currentTime -= seconds)

export const togglePlayPause = (videoPlayer: HTMLVideoElement, videoWrapper: HTMLDivElement) => {
  if (videoPlayer.paused) {
    videoPlayer.play()
  } else {
    videoPlayer.pause()
  }

  videoWrapper.dataset.videoIsPlaying = videoPlayer.paused ? 'false' : 'true'
  togglePlayBigButton(videoWrapper)
}

const togglePlayBigButton = (wrapperElement: HTMLDivElement) => {
  const playBigButton = wrapperElement.querySelector(`.${PLAY_VIDEO_BUTTON_CLASS}`)

  if (playBigButton && !playBigButton.classList.contains(BUTTON_HIDDEN_CLASS)) {
    playBigButton.classList.add(BUTTON_HIDDEN_CLASS)
  }
}

export const setVolume = (event: Event, videoPlayer: HTMLVideoElement, volumeWrapper: HTMLElement) => {
  const targetElement = event.target as HTMLInputElement
  const value = targetElement.value
  videoPlayer.volume = +value / 100
  volumeWrapper.dataset.prevValue = `${value}`
  fillInputVolume(targetElement, +value)
  toggleVolumeIcons(volumeWrapper, +value)
}

export const toggleVolume = (videoPlayer: HTMLVideoElement, volumeWrapper: HTMLElement) => {
  const prevValue = +volumeWrapper.dataset.prevValue / 100
  const inputRange = volumeWrapper.querySelector('input')
  const newValue = inputRange.value === '0' ? prevValue : 0
  inputRange.value = `${newValue * 100}`
  videoPlayer.volume = newValue
  fillInputVolume(inputRange, +inputRange.value)
  toggleVolumeIcons(volumeWrapper, newValue)
}

const fillInputVolume = (element: HTMLInputElement, value: number) => {
  const progress = (+value / +element.max) * 100
  element.style.background = `linear-gradient(to right, #FFF ${progress}%, #737373 ${progress}%)`
}

const toggleVolumeIcons = (volumeWrapper: HTMLElement, value: number) => {
  const icons = volumeWrapper.querySelectorAll(`.${BUTTON_ICON_CLASS}`)
  icons?.forEach(icon => {
    if (icon.classList.contains(ICON_SOUND_CLASS)) {
      icon.classList.toggle(HIDDEN_ICON_CLASS, value === 0)
    }
    if (icon.classList.contains(ICON_NO_SOUND_CLASS)) {
      icon.classList.toggle(HIDDEN_ICON_CLASS, value > 0)
    }
  })
}

export const updateVideoTime = (event: Event, wrapperElement: HTMLDivElement, updateProgressBar = false) => {
  const data = event.target as HTMLVideoElement

  const currentTimeDiv: HTMLDivElement = wrapperElement.querySelector(`.${VIDEO_CURRENT_TIME_CLASS}`)
  const durationTimeDiv: HTMLDivElement = wrapperElement.querySelector(`.${VIDEO_DURATION_TIME_CLASS}`)

  if (currentTimeDiv) {
    currentTimeDiv.innerText = `${formatVideoTime(data.currentTime)}`
  }
  if (durationTimeDiv) {
    durationTimeDiv.innerText = `${formatVideoTime(data.duration)}`
  }

  wrapperElement.dataset.videoEnded = data.paused && data.currentTime === data.duration ? 'true' : 'false'

  if (updateProgressBar) {
    updateProgressBarDivs({ wrapperElement, currentTime: data.currentTime, duration: data.duration })
    updateBufferedTime(event, wrapperElement)
  }
}

const updateProgressBarDivs = ({
  wrapperElement,
  currentTime,
  duration,
}: {
  wrapperElement: HTMLDivElement
  currentTime: number
  duration: number
}) => {
  const progressBarDiv: HTMLDivElement = wrapperElement.querySelector(`.${VIDEO_PROGRESS_BAR_PROGRESS_CLASS}`)
  const currentTimeDiv: HTMLDivElement = wrapperElement.querySelector(`.${VIDEO_PROGRESS_BAR_CURRENT_TIME_CLASS}`)

  if (progressBarDiv) {
    progressBarDiv.style.width = `${(currentTime / duration) * 100}%`
  }

  if (currentTimeDiv) {
    currentTimeDiv.innerText = formatVideoTime(currentTime)
    currentTimeDiv.style.left = `${(currentTime / duration) * 100}%`
  }
}

export const updateBufferedTime = (event: Event, wrapperElement: HTMLDivElement) => {
  const videoElement = event.target as HTMLVideoElement
  const bufferedDiv: HTMLDivElement = wrapperElement.querySelector(`.${VIDEO_PROGRESS_BAR_BUFFER_CLASS}`)

  if (bufferedDiv) {
    for (let i = 0; i < videoElement.buffered.length; i++) {
      if (videoElement.buffered.start(videoElement.buffered.length - 1 - i) < videoElement.currentTime) {
        bufferedDiv.style.width = `${(videoElement.buffered.end(videoElement.buffered.length - 1 - i) * 100) / videoElement.duration}%`
        break
      }
    }
  }
}

export const togglePictureInPicture = (videoPlayer: HTMLVideoElement, wrapperElement: HTMLDivElement) => {
  if (document.pictureInPictureElement) {
    document.exitPictureInPicture()
    wrapperElement.dataset.miniplayer = 'false'
  } else if (document.pictureInPictureEnabled) {
    videoPlayer.requestPictureInPicture()
    wrapperElement.dataset.miniplayer = 'true'
  }
}

export const toggleFullScreen = (wrapperElement: HTMLDivElement) => {
  if (!document.fullscreenElement) {
    wrapperElement.requestFullscreen()
    wrapperElement.dataset.fullScreen = 'true'
  } else if (document.exitFullscreen) {
    document.exitFullscreen()
    wrapperElement.dataset.fullScreen = 'false'
  }
}

export const formatVideoTime = (duration: number): string => {
  const secNum = Math.floor(duration) // don't forget the second param
  const hours = Math.floor(secNum / 3600)
  let minutes: string | number = Math.floor((secNum - hours * 3600) / 60)
  let seconds: string | number = secNum - hours * 3600 - minutes * 60
  let hoursString: string

  if (hours < 10) {
    hoursString = hours > 0 ? `0${hours}:` : ''
  }
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  if (seconds < 10) {
    seconds = `0${seconds}`
  }
  return `${hoursString}${minutes}:${seconds}`
}

const setElementAttributes = (element: HTMLDivElement, attributes: AttributesType) => {
  const entries = Object.entries(attributes)
  entries?.forEach(entry => element.setAttribute(entry[0], entry[1]))
}

export const observeDecorativeVideos = ({
  videoWrapperElement,
  videoPlayer,
  disablePlayPauseDecorativeOnMobile = 'false',
  playPauseOnHover = 'false',
}) => {
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (videoPlayer) {
          if (playPauseOnHover === 'true') {
            registerPlayPauseOnHoverEvents(videoWrapperElement, videoPlayer, disablePlayPauseDecorativeOnMobile)
          }
          if (entry.isIntersecting) {
            videoPlayer.play()
          } else {
            videoPlayer.pause()
          }
        }
      })
    },
    {
      threshold: [0.2],
    },
  )

  const { mobileVideoDisabled } = videoWrapperElement?.dataset || {}

  if (isMobile && mobileVideoDisabled === 'true') {
    return
  }

  if (videoPlayer) {
    observer.observe(videoWrapperElement)
  }
}

const registerPlayPauseOnHoverEvents = (element: HTMLDivElement, videoPlayer: HTMLVideoElement, disablePlayPauseDecorativeOnMobile: string) => {
  const disabledOnMobile = disablePlayPauseDecorativeOnMobile === 'true'

  element.addEventListener('mouseover', () => {
    if (isMobile && disabledOnMobile) {
      return
    }

    if (videoPlayer.paused) {
      videoPlayer.play()
    }
  })

  element.addEventListener('mouseleave', () => {
    if (isMobile && disabledOnMobile) {
      return
    }

    videoPlayer.pause()
  })
}
