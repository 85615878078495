export const breakpointValues = {
  downSm: 599,
  downMd: 959,
  downLg: 1239,
  downXl: 1439,
  upSm: 600,
  upMd: 960,
  upLg: 1240,
  upXl: 1440,
}

/**
 * site breakpoints
 */
const breakpoints = {
  'down-sm': `(max-width: ${breakpointValues.downSm}px)`,
  'down-md': `(max-width: ${breakpointValues.downMd}px)`,
  'down-lg': `(max-width: ${breakpointValues.downLg}px)`,
  'down-xl': `(max-width: ${breakpointValues.downXl}px)`,

  'up-sm': `(min-width: ${breakpointValues.upSm}px)`,
  'up-md': `(min-width: ${breakpointValues.upMd}px)`,
  'up-lg': `(min-width: ${breakpointValues.upLg}px)`,
  'up-xl': `(min-width: ${breakpointValues.upXl}px)`,
}

/**
 * creates matchMedia object
 * @param {string} str - the breakpoint
 * usage: const mediaQueryList = breakpoint('down-md')
 * mediaQueryList.onchange = e => {
 *  if(e.matches) {
 *    // do something
 *  }
 * }
 */
export const breakpoint = str => (breakpoints[str] ? window.matchMedia(breakpoints[str]) : new Error('undefinded breakpoint'))

export const isMobile = window.innerWidth <= breakpointValues.downMd

export const isMobileHeader = window.innerWidth <= breakpointValues.downLg

export const isTablet = window.innerWidth >= breakpointValues.upSm && window.innerWidth <= breakpointValues.downMd

export function getIsMobile() {
  // Useful to be called on resize event, so we support mobile device rotation
  return window.innerWidth <= breakpointValues.downMd
}

export function getIsMobileSmall() {
  return window.innerWidth <= breakpointValues.downSm
}

export function getIsTablet() {
  return window.innerWidth >= breakpointValues.upSm && window.innerWidth <= breakpointValues.downMd
}

export function getIsDesktop() {
  return window.innerWidth >= breakpointValues.upMd
}
